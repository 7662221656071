const auth = {
  namespaced: true,
  state: {
    user: null,
  
    authorized: false,
    observers: {
      user: null,
      onlineUsers: null
    },
    updateStatus: null,
    onlineUsers: []
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
      if (payload) {
        localStorage.setItem(this._vm.$config.userKey, JSON.stringify(payload))
        this._vm.$api.defaults.headers.common['Authorization'] =  `bearer ${payload.token}`
      } else {
        localStorage.removeItem(this._vm.$config.userKey)
      }
    },
    setAuthorized (state, payload) { state.authorized = payload },
    setUpdateStatus (state, payload) { state.updateStatus = payload },
    setOnlineUser (state, payload) {
      const ix = state.onlineUsers.findIndex(x => x.id == payload.id)
      if (ix > -1) { 
        this._vm.$set(state.onlineUsers, [ix], payload) 
      } else {
        state.onlineUsers.unshift(payload)
      }
    },
    removeOnlineUser (state, payload) { 
      const ix = state.onlineUsers.findIndex(x => x.id == payload.id)
      if (ix > -1) { state.onlineUsers.splice(ix, 1) }
    },
    resetOnlineUsers (state) { state.onlineUsers = [] },
  },
  actions: {
    // AUTH Changed
    authChanged ({state, commit, dispatch, rootState}, {$router}) {
      commit('setLoading', {stats: true}, {root: true})
      return this._vm.$auth().onAuthStateChanged(async user => {

        const current = $router.history.current
        if (user) {
          await dispatch('getIdToken').then(async idToken => {
            if (state.observers.user) { 
              state.observers.user() 
            }

            state.observers.user = await this._vm.$db().collection('users')
            .doc(user.uid)
            .onSnapshot(async doc => {
              const data = doc.data()
              let payload = {
                ...user.providerData[0],
                token: idToken.token,
                claims: idToken.claims,
                ...data,
                uid: doc.id,
                superAdmin: data.superAdmin
              }

              // Get Configs
              await dispatch('getConfigs', {user: payload, $router },  {root: true})

              // Update User
              await dispatch('updateUser', {user: payload, $router})

              if (!state.authorized) {
                commit('setAuthorized', true)
                
                // dispatch('sendStatus', {id: state.user.uid, status: 'logon'})
                // dispatch('sendStatus', {id: state.user.uid, status: 'online'})
                // dispatch('onlineUsers')
                // commit('setUpdateStatus', setInterval(function(){ 
                //   dispatch('sendStatus', {id: state.user.uid, status: 'online'})
                //   dispatch('onlineUsers')
                // }, 1000*60))
              }
              if (payload.updatePass) { 
                $router.push('/profile') 
              }
            })
          })
          .catch(err => {
            console.error(err)
          })
          const companies = ['/samtronic', '/portao_cambui']
          if(companies.includes(current.path) || current.path == '/' || current.path == '/login' || current.path == '/register' || current.path == '/finishSignUp') { 
            if(rootState.companySelected && rootState.companySelected.configs && rootState.companySelected.configs.initialRoute){
              $router.push(rootState.companySelected.configs.initialRoute)
            } else{
              $router.push('/SC5/listar') 
            }
          }
        } else {
          // clearInterval(state.updateStatus)
          // if (state.user && state.user.uid) { dispatch('sendStatus', {id: state.user.uid, status: 'logoff'}) }
          dispatch('closeListeners')
          commit('registers/resetRegisters', {}, { root: true })
          commit('registers/resetCounters', {}, { root: true })
          commit('setAuthorized', false)
          commit('setUser', null)
          const companies = ['samtronic', 'portao_cambui']
          if(companies.includes(rootState.companySelected.groupCompany)){
            $router.push(`/${rootState.companySelected.groupCompany}`) 
          } else if(!companies.includes(current.path.replaceAll('/', '')) && current.path != '/login' && current.path != '/register' && current.path != '/finishSignUp') { 
            $router.push('/login')
          }
        }

        commit('setLoading', {stats: false}, {root: true})
      })
    },
    async updateUser ({state, commit, dispatch, rootState}, {user, $router}) {
      if (!user) user = state.user

      // SA3
      user.SA3 = await dispatch('getSA3', {uid:user.uid})
      // if(user.SA3) user.SA3Subs = await dispatch('getSA3Subs', {A3_COD:user.SA3.A3_COD})
      const tenantId = await dispatch('registers/getCustomer', 'SA3', {root: true})
      const userRules = rootState.registers && rootState.registers.registers ? rootState.registers.registers.userRules : null
      const rulesCompany = user && user.companies && user.companies[tenantId] ? user.companies[tenantId].rules : null
      if (rulesCompany && rulesCompany.length > 0 && userRules && userRules.length > 0) {
        user.seeAllSalers = rulesCompany.some(rule => userRules.some(ur => ur.id === rule && ur.seeAllSalers))
      }

      if (rootState?.companySelected?.configs?.auth?.expirationPass) {
        let { expirationPass, dateReference } = rootState.companySelected.configs.auth
        // expirationPass = 1
        let dateR = new Date(dateReference.toDate())
        if (user.passChangedAt?.seconds) {
          const {nanoseconds, seconds} = user.passChangedAt
          dateR = new Date(new this._vm.$db.Timestamp(seconds, nanoseconds).toDate())
        }

        const dateReferenceStr = this._vm.$moment(dateR.setDate(dateR.getDate() + (expirationPass*1))).format("YYYYMMDD")
        const todayStr = this._vm.$moment(new Date()).format("YYYYMMDD")

        if ((todayStr >= dateReferenceStr) || isNaN(dateReferenceStr)) {
          $router.push('/expirationPass')
        }
      } 
      
      if (user.forceChangePass) {
        $router.push('/forceChangePass')
      }

      // Cria um atalho para retornar se user é admin da filial logada.
      if (
        user.companies 
        && rootState.companySelected 
        && rootState.companySelected.groupCompany
        && user.companies[rootState.companySelected.groupCompany] 
        && user.companies[rootState.companySelected.groupCompany].admin
      ) {
        user.isAdminCompanySelected = true
      }
     
      commit('setUser', user)
    },
    async getSA3 ({dispatch}, {uid}) {
      const collection = 'SA3'
      
      const SA3 = await dispatch('registers/get', { collection, filters: [{key: 'A3_USRPORT', operator: '==', value: uid}], limit: 1, forceFilter: true}, {root: true})

      return (SA3 && SA3.length > 0) ? SA3[0] : null
    },
    async getSA3Subs ({state, dispatch}, {SA3, superAdmin}) {
      const collection = 'SA3'
      let SA3regs = []
      const tenantId = await dispatch('registers/getCustomer', 'SA3', {root: true})

      if (superAdmin || state.user?.companies[tenantId]?.admin || state.user?.seeAllSalers) {
        const SA3All = await dispatch('registers/get', { collection, limit: 9999, filters: [{key: 'active', operator: '==', value: true}]}, {root: true})
        SA3regs = SA3All || []
      } else if (SA3) {
        // busque os registros de vendedores em que o usuário logado seja o gerente
        const SA3Geren = await dispatch('registers/get', { collection, limit: 9999, filters: [{key: 'A3_GEREN', operator: '==', value: SA3.A3_COD}]}, {root: true})
  
        // busque os registros de vendedores em que o usuário logado seja o superior
        const SA3Super = await dispatch('registers/get', { collection, limit: 9999, filters: [{key: 'A3_SUPER', operator: '==', value: SA3.A3_COD}]}, {root: true})
        SA3regs = [SA3, ...SA3Geren, ...SA3Super]
      }
      return SA3regs
    },
    async recoverUserPassword({dispatch}, {email}){

      var actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: 'https://ufmanage.ufsales.com.br',
        handleCodeInApp: false
      };

      return this._vm.$auth().sendPasswordResetEmail(email, actionCodeSettings)
        .then(res => {
          return res.user
        })
        .catch(err => {
          let message = ''
          switch (err.code) {
            case 'auth/invalid-email':
              message = 'E-mail inválido!'
              break;
            case 'auth/user-disabled':
              message = 'Usuário desabilitado!'
              break;
            case 'auth/user-not-found':
              message = 'Usuário não encontrado!'
              break;
            case 'auth/invalid-action-code':
              message = 'Link de acesso inválido. Link expirado ou já foi usado!'
              break;
            default:
              message = err.message
              break;
          }
        })
    },

    // CLOSE LISTENERS
    closeListeners ({state, rootState}) {
      // Auth
      if (state.observers.user) { state.observers.user() }
      if (state.observers.onlineUsers) { state.observers.onlineUsers() }
      
      // Registers
      const registersObservers = rootState.registers.observers
      for (const key in registersObservers) {
        if (Object.prototype.hasOwnProperty.call(registersObservers, key) && registersObservers[key]) {
          const registersSnapshots = registersObservers[key].snapshots
          registersSnapshots.forEach(el => {
            el()
          });
        }
      }
    },

    // SIGN
    signUp ({dispatch}, payload) {
      return this._vm.$api.post(`/users/`, payload)
        .then(async res => {
          await dispatch('signIn', {email: payload.email, password: payload.password})
          return res.data
        })
        .catch(err => {
          return {error: err.response.data.error || err.response.data}
        })
    },
    sendSignInLinkToEmail (context, {email}) {
      const actionCodeSettings = {
        url: `${this._vm.$config.baseUrl}/finishSignUp`,
        handleCodeInApp: true,
      };
      return this._vm.$auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem(this._vm.$config.emailForSigninKey, email)
          this._vm.$toast.success('E-mail de acesso enviado');
        })
        .catch(err => {
          this._vm.$toast.error(err.code);
          console.error(err)
        })
    },
    finishSignUp (context, {email}) {
      const href = window.location.href
      if (this._vm.$auth().isSignInWithEmailLink(href)) {
        return this._vm.$auth().signInWithEmailLink(email, href)
          .then(res => {
            window.localStorage.removeItem(this._vm.$config.emailForSigninKey);
            return res.user
          })
          .catch(err => {
            let message = ''
            switch (err.code) {
              case 'auth/invalid-email':
                message = 'E-mail inválido!'
                break;
              case 'auth/user-disabled':
                message = 'Usuário desabilitado!'
                break;
              case 'auth/user-not-found':
                message = 'Usuário não encontrado!'
                break;
              case 'auth/invalid-action-code':
                message = 'Link de acesso inválido. Link expirado ou já foi usado!'
                break;
              default:
                message = err.message
                break;
            }
  
            return {error: message}
          });
      } else {
        return {error: 'Link de acesso inválido!'}
      }
    },
    signIn ({commit}, {user, sessionType}) {
      return this._vm.$auth().setPersistence(sessionType)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        const email = user?.email?.trim() || '';
        return this._vm.$auth().signInWithEmailAndPassword(email, user.password)
        .then(res => {
          return res.user
        })
        .catch(err => {
          console.error(err);
          let message = ''
          switch (err.code) {
            case 'auth/operation-not-allowed':
              message = 'Login por senha desativado!\nEntre em contato com desenvolvedor.'
              break;
            case 'auth/invalid-email':
              message = 'E-mail inválido!'
              break;
            case 'auth/user-disabled':
              message = 'Usuário desabilitado!'
              break;
            case 'auth/user-not-found':
              message = 'Usuário não encontrado!'
              break;
            case 'auth/wrong-password':
              message = 'Senha é inválida!'
              break;
            default:
              message = err.message
              break;
          }

          return {error: message}
        });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
      });

      
    },
    sendStatus (context, {id, status}) {
      return this._vm.$api.post(`/users/${id}/status`, {status})
        .then(res => res)
        .catch(error => {
          throw error.response.data.error || error.response.data
        })
    },
    signOut () {
      this._vm.$auth().signOut()
    },
    getIdToken () {
      return this._vm.$auth().currentUser.getIdTokenResult(/* forceRefresh */ true)
        .then(idTokenResult => idTokenResult)
        .catch(err => {
          console.error(err)
          return err
        });
    },
    refreshToken ({dispatch, commit, state}) {
      return dispatch('getIdToken').then(idToken => {
        const payload = {
          ...state.user,
          token: idToken.token,
          claims: idToken.claims
        }
        commit('setUser', payload)
        commit('setAuthorized', true)
        return payload
      })
    },

    // ONLINE USERS
    async onlineUsers({state, rootState, commit, dispatch}) {
      commit('resetOnlineUsers')
      let ref = this._vm.$db().collectionGroup('status')
      ref = ref.where('lifeControl.deletedAt', '==', null)
      ref = ref.where('lifeControl.createdAt', '>', new Date(new Date().getTime()-60000))
      
      if (state.observers.onlineUsers) { state.observers.onlineUsers() }
      state.observers.onlineUsers = ref.onSnapshot(async snapshot => {
        const changes = snapshot.docChanges()
        for (const [idx, el] of changes.entries()) {
          const data = {id: el.doc.id, ...el.doc.data()}
          const parentId = el.doc.ref.parent.parent.id
          let user = (rootState.registers.registers.users) ? rootState.registers.registers.users.find(x => x.id == parentId) : null
          if (!user) { user = await dispatch('registers/getById', { collection: 'users', register: 'users', id: parentId }, {root: true}) }
          if (data.status == 'online') commit('setOnlineUser', user)
          else if (data.status == 'logoff') commit('removeOnlineUser', user)
        }
      })
    }
  }
}

export default auth
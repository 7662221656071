<template>
  <section>
    <Toolbar/>
    <DataTable 
      v-if="useDatatable"
      :opt="optDataTable"
      :collection="collection"
      :register="register"
      :filters="filters"
      :details="details"
      :externalRequest="externalRequest"
      :claims="computedClaims"
      :blockedField="blockedField"
      :blockedFunction="blockedFunction"
      :integration="integration"
      :dataFilters="dataFilters"
      :searchInputAlgolia="searchInputAlgolia"
      :inputsMaster="computedInputsMaster"
      :customActions="computedCustomActions"
      @showCarousel="(event)=>showCarousel(event)"
    />
      <template v-else>
      <v-container>
        <v-row>
          <v-col>
            <b>Nada</b>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-if="computedImagesDialog && computedImagesDialog.length > 0">
      <v-container>
        <Images
        
        :imageUrls="computedImagesDialog"
        :justCarousel="true"
        @closeDialog="imagesDialog = []"
        />
      </v-container>
    </template>
  </section>
</template>

<script>
import Toolbar from "@/components/Templates/Toolbar"
import DataTable from "@/components/Registers/DataTable"
import Images from "@/components/Templates/Images"
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState:registersState, mapActions:registersActions } = createNamespacedHelpers('registers')
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  name: "List",
  components: { DataTable, Toolbar, Images },
  props: {
    useDatatable:{
      type: Boolean,
      default: true
    },
    collection: String,
    register: {
      type: String,
      required: true,
    },
    filters: {},
    orderBy: {},
    externalRequest: Object,
    details: {
      type: Array,
      required: false,
      default: () => []
    },
    blockedField: String,
    blockedFunction: Function,
    headers: {
      type: Array,
      required: false,
    },
    claims: {
      type: Object,
      required: true,
      default: () => ({
        add: false, 
        view: false, 
        edit: false, 
        delete: false, 
      })
    },
    integration: {
      type: Boolean,
      default: false
    },
    searchInputAlgolia: {
      type: Boolean,
      default: false
    },
    dataFilters: {
      default: () => []
    },
    inputsMaster: {
      type: Array,
      default: () => []
    },
    customActions: Array,
  },
  data(){
    return {
      imagesDialog: [],
    }
  },
  computed: {
    ...mapState(['companySelected']),
    ...registersState(['registers']),
    ...authState(['user']),
    userRules() {
      return this.computedUserRules.filter(ur => (this.user.companies[this.companySelected.groupCompany].rules) ? this.user.companies[this.companySelected.groupCompany].rules.includes(ur.id) : false)
    },
    computedUserRules () {
      return this.registers['userRules']
    },
    optDataTable() {
      return {
        vModel: this.vModel,
        headers: [...this.computedHeaders, /*this.status,*/ ...this.actions],
        allHeaders: this.inputsMaster,
        items: this.registers[this.register],
        showSelect: true,
        itemKey: 'id',
        serverItemsLength: true,
        dispatch: []
      }
    },
    computedInputsMaster() {
      return this.inputsMaster
    },
    computedHeaders() {
      const inputsToList = this.inputsMaster.map(input => {
        try{
          if(input.list){
            input.list = typeof input.list == 'string' ? eval(input.list) || false : input.list
          }
        } catch (e){
          input.list = false
        }
        return input
      })

      return inputsToList.filter(input => typeof input.list == 'function' ? input.list({user: this.user}) : input.list).map(x => {
        if (x.markers) { 
          for (const key in x.markers) { 
            if (Object.hasOwnProperty.call(x.markers, key)) {
              x.markers[key.trim().toLowerCase()] = x.markers[key]
            }
          }
        }
        return { ...x, text: x.label || x.value }
      })
    },
    status() {
      return { text: 'Sincronia', value: 'data-table-status', align: 'center', tdClass: 'text-center'}
    },
    actions() {
      return [{ text: 'Ações', value: 'data-table-actions', align: 'left', tdClass: 'text-left text-no-wrap' }]
    },
    computedClaims() {
      if (!this.user) return {view: false, add: false, edit: false, delete: false, copy: false}

      let claims = this.claims

      const admin = this.user.superAdmin || this.user.companies[this.companySelected.groupCompany].admin

      claims.view = claims.view && (admin || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.view))
      claims.add = claims.add && (admin || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.add))
      claims.edit = claims.edit && (admin || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.edit))
      claims.delete = claims.delete && (admin || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.delete))
      claims.copy = claims.copy && (admin || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.copy))
      // claims.noFilters = this.userRules.find(x => x.claims && x.claims[this.register] && x.claims[this.register]?.view)

      return claims
    },
    computedCustomActions() {
      if (!this.customActions)
        return []

      return this.customActions.filter(_action => _action.data ? _action.data.listRegister : _action.listRegister).map(_action => {
        const action = _action.data ? {..._action.data} : { ..._action }

        if (!action.color)
          action.color = 'primary'

        if (!action.actionRunner || action.actionRunner.apply)
          action.actionRunner = '() => console.warn(`missing action function for custom action "${action.text}."`)'

        return action
      })
    },
    computedImagesDialog() {
      return this.imagesDialog;
    },
  },
  methods: {
    ...registersActions(['observer', 'getExternal']),
    initRegisters() {
      if (this.externalRequest && this.externalRequest.query) {
        this.getExternal({
          ...this.externalRequest,
          register: this.register 
        })
      } else {
        this.observer({
          collection: this.collection, 
          register: this.register, 
          filters: this.filters,
          orderBy: this.orderBy,
          details: this.details,
          user: this.user
        })
      }
    },
    showCarousel({ item, head }) {
      this.imagesDialog = item[head.value];
    },
  },
  created() {
    this.initRegisters()
  },
}
</script>

<style>

</style>